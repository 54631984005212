body {
    background-color: #f3f9ff !important;
}

/*resetter*/
* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

/*font size for material UI tooltip*/
.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: .75em;
}

.file-custom-content {
    padding: 0;
}

.member-bg-color {
    background-color: #f3f9ff;
}

.cs-message--outgoing .cs-message__content-wrapper {
    align-items: end !important;
}

.cs-message__content {
    width: fit-content !important;
}

.line::after {
    width: 0% !important;
}

.custom-popup {
    max-width: 400px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}
