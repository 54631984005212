.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default !important;
}
.not-clickable:hover {
  color: rgba(0, 0, 0, 0.4) !important;
}

.newsfeed {
  display: flex;
  position: relative;
}
.newsfeed .main-panel {
  flex-basis: 744px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  min-width: 0;
}
.newsfeed .main-panel .post-form .flex {
  display: flex;
  margin-bottom: 10px;
}
.newsfeed .main-panel .post-form .flex .image-container {
  width: 45px;
}
.newsfeed .main-panel .post-form .flex .image-container .image {
  width: 3em !important;
  height: 3em !important;
}
.newsfeed .main-panel .post-form .flex .textarea-container {
  width: 95%;
}
.newsfeed .main-panel .post-form .flex .textarea-container .text-editor {
  padding: 5px;
  border: none;
  width: 100%;
  resize: vertical;
  background-color: #f3f9ff;
  min-height: 100px;
  cursor: text;
}
.newsfeed .main-panel .post-form .flex .textarea-container .text-editor .rdw-editor-wrapper .rdw-editor-main .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  min-height: 100px;
}
.newsfeed .main-panel .post-form .flex .textarea-container .text-editor .rdw-editor-toolbar {
  display: none;
}
.newsfeed .main-panel .post-form .flex .textarea-container .text-editor .public-DraftStyleDefault-block {
  margin: 0 !important;
}
.newsfeed .main-panel .post-form .flex .textarea-container textarea {
  padding: 5px;
  border: none;
  width: 100%;
  resize: vertical;
  background-color: #f3f9ff;
}
.newsfeed .main-panel .post-form .flex .textarea-container .file-button {
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  stroke: #97a4b5;
}
.newsfeed .main-panel .post-form .flex .textarea-container .post-button {
  height: 2.5em;
  border: none;
  padding: 4px 8px;
  min-width: 100px;
  border-radius: 4px;
  background-color: #1692ff;
  color: #fff;
  float: right;
  margin-top: 10px;
  cursor: pointer;
}
.newsfeed .main-panel .post-form .flex .textarea-container .post-button.cancel {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.6);
}
.newsfeed .main-panel .post-form .input-attachments {
  width: 100%;
}
.newsfeed .main-panel .post-form .input-attachments .post-attachment-image, .newsfeed .main-panel .post-form .input-attachments .post-attachment-video {
  background-color: black;
  background-color: #fff;
  height: auto;
  position: relative;
  width: 100%;
}
.newsfeed .main-panel .post-form .input-attachments .post-attachment-image:hover, .newsfeed .main-panel .post-form .input-attachments .post-attachment-video:hover {
  cursor: pointer;
}
.newsfeed .main-panel .post-form .input-attachments .post-attachment-image img, .newsfeed .main-panel .post-form .input-attachments .post-attachment-image video, .newsfeed .main-panel .post-form .input-attachments .post-attachment-video img, .newsfeed .main-panel .post-form .input-attachments .post-attachment-video video {
  width: 100%;
}
.newsfeed .main-panel .post-form .input-attachments .post-attachment-image .loading-image .loading, .newsfeed .main-panel .post-form .input-attachments .post-attachment-video .loading-image .loading {
  display: flex;
  justify-content: center;
}
.newsfeed .main-panel .post-form .input-attachments .post-attachment-image .close-icon, .newsfeed .main-panel .post-form .input-attachments .post-attachment-video .close-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  font-size: 1.5em;
}
.newsfeed .main-panel .post-form .input-attachments .file-attachment {
  background-color: black;
  background-color: #fff;
  height: auto;
  position: relative;
  border-radius: 5px;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin: 10px;
}
.newsfeed .main-panel .post-form .input-attachments .file-attachment:hover {
  text-decoration: underline;
  cursor: pointer;
}
.newsfeed .main-panel .post-form .input-attachments .file-attachment .close-icon {
  float: right;
  margin: 0 5px 5px 5px;
  cursor: pointer;
  font-size: 1.5em;
}
.newsfeed .main-panel .post-list .post {
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 16px;
  padding-top: 1.5em;
}
.newsfeed .main-panel .post-list .post .header {
  display: flex;
  padding: 0 1em;
}
.newsfeed .main-panel .post-list .post .header img {
  width: 2.6em;
  height: 2.6em;
}
.newsfeed .main-panel .post-list .post .header .name {
  margin-left: 10px;
  font-weight: bold;
  display: block;
  font-size: 14px;
}
.newsfeed .main-panel .post-list .post .header .date {
  margin-left: 10px;
  font-size: 12px;
  color: #97a4b5;
}
.newsfeed .main-panel .post-list .post .header .menu {
  margin-left: auto;
  cursor: pointer;
}
.newsfeed .main-panel .post-list .post .post-message {
  padding: 0 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  white-space: pre-line;
  overflow: auto;
}
.newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .left, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .right {
  width: 50%;
}
.newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .left .post-attachment-image, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .left .post-attachment-video, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .right .post-attachment-image, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .right .post-attachment-video {
  height: 230px;
  overflow: hidden;
}
.newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .left .post-attachment-image .image-overlay, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .left .post-attachment-video .image-overlay, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .right .post-attachment-image .image-overlay, .newsfeed .main-panel .post-list .post .post-attachments .image-video-attachments .right .post-attachment-video .image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 32px;
  padding: 4px 8px;
  margin: 0;
  border-radius: 1rem;
  padding: 10px;
}
.newsfeed .main-panel .post-list .post .post-attachments.editing {
  padding: 0 2rem;
}
.newsfeed .main-panel .post-list .post .post-attachments .post-attachment-image, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-video {
  background-color: black;
  background-color: #fff;
  height: auto;
  position: relative;
  width: 100%;
}
.newsfeed .main-panel .post-list .post .post-attachments .post-attachment-image:hover, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-video:hover {
  cursor: pointer;
}
.newsfeed .main-panel .post-list .post .post-attachments .post-attachment-image img, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-image video, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-video img, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-video video {
  width: 100%;
}
.newsfeed .main-panel .post-list .post .post-attachments .post-attachment-image .loading-image .loading, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-video .loading-image .loading {
  display: flex;
  justify-content: center;
}
.newsfeed .main-panel .post-list .post .post-attachments .post-attachment-image .close-icon, .newsfeed .main-panel .post-list .post .post-attachments .post-attachment-video .close-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  font-size: 1.5em;
}
.newsfeed .main-panel .post-list .post .post-attachments .file-attachment {
  background-color: black;
  background-color: #fff;
  height: auto;
  position: relative;
  border-radius: 5px;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin: 10px;
}
.newsfeed .main-panel .post-list .post .post-attachments .file-attachment:hover {
  text-decoration: underline;
  cursor: pointer;
}
.newsfeed .main-panel .post-list .post .post-attachments .file-attachment .close-icon {
  float: right;
  margin: 0 5px 5px 5px;
  cursor: pointer;
  font-size: 1.5em;
}
.newsfeed .main-panel .post-list .post .stats {
  margin: 1rem 1rem 0rem 1rem;
  height: 30px;
}
.newsfeed .main-panel .post-list .post .stats .like-stats {
  float: left;
}
.newsfeed .main-panel .post-list .post .stats .like-stats i.icon, .newsfeed .main-panel .post-list .post .stats .like-stats i.icons {
  font-size: 1.3em;
}
.newsfeed .main-panel .post-list .post .stats .comment-stats {
  float: right;
}
.newsfeed .main-panel .post-list .post hr {
  height: 2px;
  background-color: #f3f9ff;
  border: none;
}
.newsfeed .main-panel .post-list .post .post-actions {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 0 24px;
  height: 35px;
  padding-top: 6px;
}
.newsfeed .main-panel .post-list .post .post-actions .post-action {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  margin: 0 20px;
  border-radius: 16px;
}
.newsfeed .main-panel .post-list .post .post-actions .post-action.liked {
  color: #1592ff;
}
.newsfeed .main-panel .post-list .post .post-actions .post-action:hover {
  background-color: #f5f5f5;
}
.newsfeed .main-panel .post-list .post .comment-section {
  margin: 1rem 1rem 0rem 1rem;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form {
  margin: auto;
  width: 100%;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header {
  display: flex;
  padding: 0 1em;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header.no-avatar {
  padding: 5px 0;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header .text-editor {
  padding: 5px;
  border: none;
  width: 100%;
  resize: vertical;
  background-color: #f3f9ff;
  min-height: 100px;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header .text-editor .rdw-editor-toolbar {
  display: none;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header .text-editor .rdw-editor-wrapper .rdw-editor-main .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  min-height: 100px;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header .text-editor .public-DraftStyleDefault-block {
  margin: 0 !important;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-header textarea {
  padding: 5px;
  border: none;
  width: 100%;
  resize: vertical;
  background-color: #f3f9ff;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-form-actions {
  margin-top: 10px;
  height: 60px;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-form-actions button {
  float: right;
  margin-right: 10px;
  width: 120px;
  background-color: #f5f5f5;
  padding: 10px;
  min-width: unset;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-form .comment-form-actions button.save {
  background-color: #1692ff;
  color: #fff;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  margin: auto;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list .ui.comments {
  margin: 0.5em 0;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list .comment .avatar {
  height: 2.2em;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list .comment .avatar img {
  border-radius: 50%;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list .comment .content {
  position: relative;
  padding-top: 5px;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list .comment .content .comment-menu {
  position: absolute;
  top: 0;
  right: 0;
}
.newsfeed .main-panel .post-list .post .comment-section .comment-list .comment .content .text {
  margin: 0 0 0.5em;
}
.newsfeed .side-panel {
  flex-basis: 288px;
  background-color: #fff;
  height: 94vh;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 0;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .newsfeed .side-panel {
    display: none;
  }
}
.newsfeed .side-panel .birthdays {
  padding: 30px 10px;
}
.newsfeed .side-panel .birthdays .comment {
  height: 4em !important;
  margin: 0 !important;
  padding: 0 !important;
}
.newsfeed .side-panel .birthdays .comment .avatar {
  width: 3em !important;
  height: 3em !important;
}
.newsfeed .side-panel .birthdays .comment .avatar img {
  border-radius: 50%;
}
.newsfeed .side-panel .birthdays .comment .content {
  padding-top: 10px !important;
  padding-right: 5px !important;
}
