.calendar .fc-view-harness.fc-view-harness-active {
  height: 1131px !important;
}
.calendar.disabled {
  pointer-events: none;
  background-color: #fff;
  opacity: 0.7;
  height: 100%;
  width: 100%;
}
.calendar .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 10em;
  position: relative;
}
.calendar a.fc-col-header-cell-cushion {
  color: #000;
}
.calendar .fc-daygrid-day-number {
  color: #000;
}
.calendar .fc-today-button {
  background-color: #1592ff !important;
  border-color: #1592ff !important;
}
.calendar .fc-today-button.fc-button.fc-button-primary:first-letter {
  text-transform: uppercase;
}
.calendar .fc-today-button {
  background-color: #318CE7;
  border-color: #318CE7;
}
.calendar .fc-next-button {
  background-color: #ffffff;
  border-color: #000000;
  color: black;
}
.calendar .fc-prev-button {
  background-color: #ffffff;
  border-color: #000000;
  color: black;
}
.calendar .fc-daygrid-event {
  white-space: normal;
}
.calendar .fc-button-primary:focus {
  box-shadow: none;
}
.calendar .fc-button-primary:focus-visible {
  box-shadow: none;
}
.calendar .custom-event {
  padding: 5px;
  width: 100%;
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
  border-radius: 3px;
}
.calendar .schedule-event {
  background-color: #3788d8;
  border-color: #3788d8;
  color: white;
}
.calendar .schedule-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .schedule-event:hover {
  color: #2c3e50;
}
.calendar .dayoff-event {
  background-color: #808080;
  border-color: #808080;
  color: white;
}
.calendar .dayoff-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .dayoff-event:hover {
  color: #2c3e50;
}
.calendar .holiday-event {
  background-color: #00b050;
  border-color: #00b050;
  color: white;
}
.calendar .holiday-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .holiday-event:hover {
  color: #2c3e50;
}
.calendar .regular-holiday-event {
  background-color: #00b050;
  border-color: #00b050;
  color: white;
}
.calendar .regular-holiday-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .regular-holiday-event:hover {
  color: #2c3e50;
}
.calendar .special-holiday-event {
  background-color: #06526e;
  border-color: #06526e;
  color: white;
}
.calendar .special-holiday-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .special-holiday-event:hover {
  color: #2c3e50;
}
.calendar .shift-schedule-event {
  background-color: #ff6f0d;
  border-color: #ff6f0d;
  color: white;
}
.calendar .shift-schedule-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .shift-schedule-event:hover {
  color: #2c3e50;
}
.calendar .leave-request-event {
  background-color: #CC293D;
  border-color: #CC293D;
  color: white;
  cursor: not-allowed;
}
.calendar .leave-request-event .fc-daygrid-event-dot {
  border: none;
}
.calendar .leave-request-event:hover {
  color: #2c3e50;
}
.calendar .no-click {
  cursor: context-menu;
}
.calendar .no-click:hover {
  color: #fff;
}
